<template>
  <main class="main education education--list" v-if="course">
    <div class="container education__container">
      <h1 class="education__title">{{ course.title }}</h1>
      <div class="modules">
        <div
          class="modules__drop"
          v-for="(module, i) in modules"
          :key="i"
          :class="{ 'modules__drop--active': isActive(i) }"
        >
          <a @click.prevent="setActive(i)" class="modules__header">
            <div class="modules__left">
              <IconComponent name="GreenCheckIcon" class="modules__status" v-show="isModulePassed(module)" />
              <IconComponent name="SlideListIcon" class="modules__state" />
              <span class="modules__title">{{ module.title }}</span>
              <span class="modules__condition" v-show="false">Доступен после прохождения «Модуль 2»</span>
            </div>
            <div class="modules__right">
              <span class="modules__count">{{ module.lessons.length }} уроков</span>
              <IconComponent name="DownIconSmall" />
            </div>
          </a>
          <div class="modules__body">
            <router-link
              :to="{ name: 'account_education_open', params: { module: module.id, lesson: lesson.id } }"
              class="modules__item"
              v-for="(lesson, i) in module.lessons"
              :key="i"
            >
              <div class="modules__item-image">
                <IconComponent
                  name="GreenCheckIcon"
                  class="modules__item-check"
                  v-show="!!lesson.auth_passed_lesson"
                />
                <!--                <span class="modules__item-time" v-if="lesson.video">-->
                <!--                  {{ lesson.video.duration }}-->
                <!--                </span>-->
                <ImgComponent :head_img="lesson.img" />
              </div>
              <span class="modules__item-title">{{ lesson.title }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </main>
  <PageNotFound v-else />
</template>

<script>
// import LockIcon from "components/svg/LockIcon.vue";
import MY_COURSE from "gql/auth/my_course.graphql";
import PageNotFound from "@/views/PageNotFound.vue";
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  async asyncData({ route, res, store, apollo }) {
    if (!route.params && !route.params.id) {
      res.status(404);
    } else {
      await apollo.defaultClient
        .query({
          query: MY_COURSE,
          variables: {
            id: parseInt(route.params.id),
          },
          fetchPolicy: "network-only",
          context: {
            headers: {
              Authorization: "Bearer " + store.state.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          store.state.tmp.course = data.my_course;
          store.state.info = data.feedback;
        })
        .catch(() => {
          store.state.tmp.haveError = true;
        });
    }
  },
  name: "AccountEducation",
  data() {
    return {
      activeModules: [],
    };
  },
  computed: {
    course() {
      return this.$store.state.tmp.course;
    },
    modules() {
      return this.course.my_modules.sort((a, b) => {
        return a.position - b.position;
      });
    },
  },
  mounted() {
    if (this.$store.state.tmp.haveError) {
      this.$notify({
        title: "Ошибка",
        text: "Во время загрузки данных произошла ошибка, попробуйте перезагрузить страницу",
        duration: 8000,
        speed: 200,
        type: "error",
      });
    }
  },
  methods: {
    isModulePassed(module) {
      if (module.lessons.length === 0) {
        return false;
      } else {
        let passedCount = module.lessons.filter((lesson) => !!lesson.auth_passed_lesson).length;
        return passedCount === module.lessons.length && module.tests_completed;
      }
    },
    setActive(id) {
      const index = this.activeModules.indexOf(id);
      if (index !== -1) {
        this.activeModules.splice(index, 1);
      } else {
        this.activeModules.push(id);
      }
    },
    isActive(id) {
      return this.activeModules.indexOf(id) !== -1;
    },
  },
  components: {
    IconComponent,
    PageNotFound,
    ImgComponent,
    // LockIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/education.styl"
.modules {
  display grid
  grid-gap 15px

  &__drop {
    background: var(--gray_light);
    border-radius: 10px;

    &--active ^[0]__body {
      visibility: visible;
      height: auto;
      overflow: visible;
      opacity: 1;
      padding-bottom 25px
    }
  }

  &__header {
    padding 25px
    display flex
    justify-content space-between
    +below(932px) {
      flex-direction column
      gap 10px
      align-items flex-start
      justify-content flex-start
    }
  }

  &__left {
    display flex
    align-items center
    gap 10px
    +below(768px) {
      flex-direction column
      align-items flex-start
    }
  }

  &__right {
    display flex
    align-items center
    gap 25px
    +below(932px) {
      margin-left auto
    }
    +below(768px) {
      margin-left unset
      gap 5px
    }

    .icon {
      width 24px
      height 24px

      & svg path {
        stroke var(--dark)
      }
    }
  }

  &__count {
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: var(--black);
  }

  &__status {
    margin-right 5px
    width 24px
    height 24px
    +below(768px) {
      absolute right 25px top 25px
    }

    svg {
      width 100%
      height 100%
    }
  }

  &__state {
    width 24px
    height 24px

    svg {
      width 100%
      height 100%
    }
  }

  &__title {
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: var(--black);
  }

  &__condition {
    margin-left 5px
    font-style: italic;
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: var(--black);
    opacity: 0.3;
  }

  &__body {
    display: grid;
    grid-template-columns repeat(4, 1fr)
    grid-gap 15px
    padding 0 25px 0
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: var(--transition)
    +below(1080px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(860px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(580px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display grid
    grid-gap 10px

    &-image {
      height 200px
      width 100%
      position relative

      img {
        width 100%
        height 100%
        object-fit cover
        object-position center
        border-radius: 10px;
      }
    }

    &-check {
      absolute left 15px top 15px
    }

    &-time {
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      font-weight: normal;
      font-size: 0.874em;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: var(--white);
      padding 5px 7px
      absolute left 15px bottom 15px
    }

    &-title {
      font-weight: 500;
      font-size: 0.874em;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: var(--black);
    }
  }
}
</style>
