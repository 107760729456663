<template>
  <main class="not-found">
    <div class="not-found__left">
      <router-link :to="{ name: 'home' }" class="not-found__logo">
        <img src="/static/svg/logo.svg" alt="logo" />
      </router-link>
      <div class="not-found__text">
        <h1 class="not-found__text-title">Ошибка 404, потому что</h1>
        <h3 class="not-found__text-subtitle">Такой страницы пока не существует</h3>
      </div>
      <div class="not-found__btn">
        <h3 class="not-found__btn-title">Но мы подумаем как ее использовать, а пока вы можете</h3>
        <router-link :to="{ name: 'home' }" class="btn btn--main btn--md">
          <span>Вернуться на главную</span>
        </router-link>
      </div>
    </div>
    <div class="not-found__right">
      <img src="/static/svg/404.svg" alt="404 icon" />
    </div>
  </main>
</template>

<script>
export default {
  components: {},
  async asyncData({ res }) {
    res.status(404);
  },
};
</script>

<style lang="stylus">
.not-found {
  display flex
  width 100%
  min-height 100vh

  &__logo {
    width 100%
    max-width 195px
  }

  &__left {
    width 100%
    display grid
    align-items center
    justify-content center
    padding: 100px 0
    +below(1300px) {
      padding 50px 20px
    }
    +below(383px) {
      text-align center
    }
  }

  &__right {
    background var(--dark)
    color var(--white)
    width 100%
    display grid
    align-items center
    justify-content center
    +below(1300px) {
      padding 0 20px
    }
    +below(650px) {
      display none
    }

    img {
      max-width 597px
      max-height 299px
    }
  }

  &__text {
    display flex
    flex-direction column
    gap 20px
    margin-top 120px
    +below(1027px) {
      margin-top 60px
    }
    +below(837px) {
      margin-top 30px
    }
    +below(334px) {
      gap 10px
    }
    &-title {
      color var(--pink)
      font-size 2.5em
      line-height 48px
      +below(1027px) {
        font-size 2em
      }
      +below(837px) {
        font-size 1.5em
      }
      +below(334px) {
        font-size 1.25em
        line-height 38px
      }
    }
    &-subtitle {
      font-size 1.875em
      line-height 38px
      margin-bottom 0
      +below(1027px) {
        font-size 1.5em
      }
      +below(837px) {
        font-size 1em
      }
      +below(334px) {
        font-size 0.875em
        line-height 28px
      }
    }
  }

  &__btn {
    display grid
    grid-gap 60px
    margin-top 100px
    +below(1027px) {
      margin-top 50px
      gap 30px
    }
    +below(837px) {
      margin-top 20px
    }

    &-title {
      color var(--gray-4-dark)
      margin-bottom 0
      +below(1027px) {
        font-size 1em
      }
      +below(837px) {
        font-size 0.75em
      }
    }

    & .btn--md {
      max-width 300px
      height 63px
      font-size 1.125em
      line-height 23px
      +below(650px) {
        max-width 100%
      }
      +below(383px) {
        height 50px
      }
    }
  }
}
</style>
